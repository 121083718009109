import React from "react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Dropdown, SearchInput } from "@ryerson/frontend.form-controls";
import { Button } from "@ryerson/frontend.button";
import { KnowWhatINeedSearchProps } from "./KnowWhatINeed";
import SelectedProduct from "./Shared/SelectedProduct";
import { useApplication } from "@ryerson/frontend.application";
import { Alert } from "@ryerson/frontend.notification";
import EditZipModal from "./Shared/EditZipModal/EditZipModal";
import { useEcommerce } from "@ryerson/frontend.e-commerce";
import { UOM } from "@ryerson/common.enums";
import { QuantityUOM } from "@ryerson/frontend.quantity-uom-inputs";
import { DebouncerPlusEvents, UnitOfMeasureType } from "@enums/common.enums";
import { UOMValue } from "@ryerson/common.uom";
import { ProductList } from "./ProductListDesktop";

const LabelContainer = styled(Flex)`
	padding-bottom: 15px;
`;

const ButtonContainer = styled.div`
	padding-top: 35px;
`;

const desktopPadding = css`
	padding: 30px 0 60px 0;
`;

const zipLabelPadding = css`
	padding-right: 5px;
`;

const controlSize = "xl";

const ErrorContainer = styled(Flex)`
	position: relative;
	${(props: any) => {
		const { color } = props;
		return css`
			border-top: 1px solid ${color};
		`;
	}};
`;

const ErrorMessage = styled(Typography)`
	position: absolute;
	padding: 20px;
	width: 100%;
	z-index: 1;
	${(props: any) => {
		const { bgColor, bColor } = props;
		return css`
			background: ${bgColor};
			border-bottom: 1px solid ${bColor};
		`;
	}};
`;

const KnowWhatINeedDesktop: React.FC<KnowWhatINeedSearchProps> = ({
	testingId,
	searchInputContainerRef,
	setShowDropdown,
	onChangeText,
	displaySearchTerm,
	searchTerm,
	showZipModal,
	setShowZipModal,
	showDropdown,
	searchResult,
	isError,
	onClickSelectItem,
	showSelectedProduct,
	selectedProduct,
	quantity,

	qtyUom,
	qtyUomDropdown,
	onChangeUnitOfMeasure,
	priceUomDropdown,
	priceUom,
	setPriceUom,
	onClickAddToCart,
	onIconClick,
	content,
	setPrice,
	getQueryString,
	leadTimeAndDeliveryDate,
	setLeadTimeAndDeliveryDate,
	canCartViewEdit,
	isValidZipCode,
	stateMessenger,
	isRetrieving,
	setIsRetrieving,
	retrievePricing,
	setRetrievePricing,
	canCallPricing,
	publishMessage,
	setPricingResponseQuantity,
	message,
	setMessage,
}) => {
	const { theme } = useTheme();

	const label = theme.colors.primary.lightGray;
	const { darkGray, white } = theme.colors.primary;
	const {
		localization: { language },
		zipCode,
	} = useApplication();
	const { customerPickup, salesPlant } = useEcommerce();

	//----------------------------------
	// BEGIN KEYBOARD INTEGRATION CODE
	//----------------------------------
	enum SelectKeys {
		ArrowDown = "ArrowDown",
		ArrowUp = "ArrowUp",
		Enter = "Enter",
	}

	const quantityInputRef = React.useRef<HTMLInputElement>(null);

	const [productSelectedIndex, setProductSelectedIndex] = React.useState<number>(-1);
	const [isDebouncing, setIsDebouncing] = React.useState<boolean>(false);

	const resetFocus = React.useRef<boolean>(false);
	const isButtonFocused = React.useRef<boolean>(false);
	const hasPermissionRef = React.useRef<boolean>(false);
	const buttonClassName = "what-I-need-add-to-cart-button";
	const searchInputID = "quick-entry-search-input";
	const qtyUomID = "quantity-uom-input-uom";
	const qtyUomDropdownID = "quantity-uom-dropdown-uom";
	const LI_HEIGHT = 140;
	const OUTLINE = `2px solid ${theme.colors.primary.primaryBrand}`;

	React.useEffect(() => {
		if (!!selectedProduct) {
			const elInput = document?.querySelector(`#${qtyUomID}`);
			setTimeout(() => {
				elInput && (elInput as any)?.blur();
				elInput && (elInput as any)?.focus();
			}, 20);
		}
	}, [selectedProduct]);

	const isAddToCartDisabled =
		!quantityInputRef?.current?.value || isDebouncing || Number(quantity) === 0;

	// Note: used when using tab keys to navigate across the quick entry and loop
	// back to the search input
	const handleFocusChanged = (e: React.FocusEventHandler<HTMLDivElement> | undefined | any) => {
		if (e.target?.className?.includes(buttonClassName)) {
			isButtonFocused.current = true;
			resetFocus.current = true;
		} else {
			if (e.target?.id === "quantity-uom-input-quick-entry") {
				setShowDropdown(false);
			}
			isButtonFocused.current = false;
			resetFocus.current = false;
		}

		let pricingUOMwrapper = document?.querySelector(".puom-wrapper");

		// Pricing UOM cust border outline for wrapper on focus
		if (e.target?.id === "puom") {
			(pricingUOMwrapper as any).style.outline = OUTLINE;
		} else {
			(pricingUOMwrapper as any).style.outline = "unset";
		}

		// UOM dropdown set custom border outline for wrapper of input on focus event
		if (e.target?.id === `${qtyUomDropdownID}`) {
			let wrapper = findAncestor(e.target, "select-wrapper") as any;
			!!wrapper && (wrapper.style.outline = OUTLINE);
		} else {
			let uomDropDown = document?.querySelector(`${qtyUomDropdownID}`) as any;
			let wrapper = uomDropDown && findAncestor(uomDropDown, "select-wrapper");

			!!wrapper && (wrapper.style.outline = "unset");
		}
	};
	//  Works with handleFocusChanged to set the focus on search input (loops around)
	const focusGuard = (e: React.KeyboardEventHandler<HTMLDivElement> | undefined | any) => {
		if (e.key === "Tab" && resetFocus.current === true) {
			resetFocus.current = false;
			const elInput = document?.querySelector(`#${searchInputID}`);
			setTimeout(() => {
				elInput && (elInput as any)?.blur();
				elInput && (elInput as any)?.focus();
			}, 20);
		}
	};
	const handleArrowKeys = (e: React.KeyboardEventHandler<HTMLDivElement> | undefined | any) => {
		if (showDropdown) {
			if (e.key === SelectKeys.ArrowDown) {
				setProductSelectedIndex((prev) => Math.min(searchResult?.length - 1, prev + 1));
				if (productSelectedIndex > 0 && productSelectedIndex < searchResult.length - 2) {
					setScrollTop(LI_HEIGHT);
				}
			} else if (e.key === SelectKeys.ArrowUp) {
				setProductSelectedIndex((prev) => Math.max(0, prev - 1));
				if (productSelectedIndex < searchResult.length - 2) {
					setScrollTop(-LI_HEIGHT);
				}
			} else if (e.key === SelectKeys.Enter) {
				const selectedProduct = searchResult[productSelectedIndex];
				if (selectedProduct) {
					onClickSelectItem(selectedProduct);
				}
			}
		}
	};

	React.useEffect(() => {
		if (productSelectedIndex >= 0) {
			setProductSelectedIndex(-1);
		}
	}, [showDropdown]);

	// Note: Utilized for scrolling the product list with arrow keys
	const setScrollTop = (amount: number) => {
		const UList = document.querySelector("ul.product-list");
		UList && (UList.scrollTop += amount);
	};

	function findAncestor(
		el: HTMLInputElement | HTMLElement | null | undefined,
		className: string
	) {
		while ((el = el?.parentElement) && !el.classList.contains(className));
		return !!el ? el : undefined;
	}

	React.useEffect(() => {
		let [messageString, value] = message;
		if (messageString === "is-debouncing") {
			setIsDebouncing(value);
		}
	}, [message]);

	React.useEffect(() => {
		// Subscribe to pub-sub  stateMessenger
		stateMessenger?.subscribe((msg: string, value: any, topic: any) =>
			setMessage([msg, value, topic])
		);

		hasPermissionRef.current = canCartViewEdit();
	}, []);

	React.useEffect(() => {
		// on render pass focus to the button if need
		if (isButtonFocused.current) {
			isButtonFocused.current && focusOnButton();
		}
	});

	function focusOnButton() {
		let buttonEl = document.querySelector(`.${buttonClassName}  div[role='button']`) as any;
		let hasOutline = buttonEl.style.outline;
		!hasOutline && buttonEl.focus();
	}
	function focusOnInput(): void {
		const elInput = document?.querySelector(`#${searchInputID}`);

		(elInput as any)?.focus();
	}
	return (
		<>
			<div
				css={css`
					#${searchInputID}:focus {
						outline: ${OUTLINE};
					}
					#${qtyUomID}:focus {
						outline: ${OUTLINE};
					}

					.${buttonClassName}:focus {
						outline: ${OUTLINE};
					}
				`}
				onKeyDown={focusGuard}
				onFocus={handleFocusChanged}
			>
				<Flex style={desktopPadding} direction="column">
					<EditZipModal
						testingId={testingId}
						showZipModal={showZipModal}
						setShowZipModal={setShowZipModal}
					/>
					{!isValidZipCode && (
						<Flex css={{ marginLeft: "-10px" }}>
							<Alert severity="error">
								<Typography type="tertiary">{content.invalidZipCode}</Typography>
							</Alert>
						</Flex>
					)}

					<Flex alignItems="flex-end">
						{/* Search Container */}
						<FlexItem grow={1}>
							<Flex
								itemRef={searchInputContainerRef}
								direction="column"
								style={css`
									padding-right: 10px;
								`}
							>
								<LabelContainer direction="column">
									<Flex alignItems="flex-end" justifyContent="space-between">
										<Flex css={{ minWidth: "190px" }}>
											<Typography color={label}>
												{content.lookingFor}
											</Typography>
										</Flex>
										<Flex alignItems="center">
											<FlexItem
												onClick={() => {
													setShowZipModal(true);
												}}
												css={{ textAlign: "end" }}
											>
												<Typography color={label} css={zipLabelPadding}>
													{customerPickup
														? content.customerPickupLabel
														: content.shippingTo}
												</Typography>
												<Typography underline="true" color={white}>
													{customerPickup &&
													salesPlant &&
													Object.keys(salesPlant).length > 0
														? `${salesPlant?.publicName}, ${salesPlant?.state}`
														: zipCode}
												</Typography>
											</FlexItem>
										</Flex>
									</Flex>
								</LabelContainer>
								<SearchInput
									onKeyDown={handleArrowKeys}
									id={searchInputID}
									value={displaySearchTerm}
									iconPosition="right"
									onChange={(e) => onChangeText(e)}
									size={controlSize}
									icon={displaySearchTerm ? "close" : undefined}
									iconBackgroundColor={
										displaySearchTerm ? theme.colors.primary.gray : ""
									}
									onIconClick={onIconClick}
									placeholder={content.search}
									language={language}
									onFocus={() => {
										setShowDropdown(searchResult.length > 0);
									}}
									dataTestId="search-input"
								/>
								{showDropdown && (
									<ProductList
										searchResults={searchResult as any}
										handleClick={onClickSelectItem}
										selectItemIndex={productSelectedIndex}
									/>
								)}
								{isError && (
									<ErrorContainer {...{ color: darkGray }}>
										<ErrorMessage {...{ bgColor: white, bColor: darkGray }}>
											{content.noResultFound.replace(
												"%searchString%",
												searchTerm.toString()
											)}
										</ErrorMessage>
									</ErrorContainer>
								)}
							</Flex>
						</FlexItem>

						{/* Quantity And UOM Fields */}
						<FlexItem css={{ paddingRight: 10, paddingTop: 1, minWidth: "290px" }}>
							<QuantityUOM
								quantityInputRef={quantityInputRef}
								stateMessenger={stateMessenger}
								disabled={
									Object.values &&
									Object.values(selectedProduct).every((value) => !value)
								}
								quantityInputWidth={150}
								uomInputWidth={120}
								DropDownLabelAddition={
									<Typography color={label}>{content.uom}</Typography>
								}
								InputLabelAddition={
									<Flex>
										<Typography color={label}>{content.quantity}</Typography>
									</Flex>
								}
								type="tertiary"
								size={"xl"}
								showLabel={false}
								id={"uom"}
								inputDataTestId={"qty"}
								dropdownDataTestId={"qty-uom"}
								itemData={selectedProduct}
								quantityUomOptions={qtyUomDropdown}
								handleChange={(
									quantityValue: number | undefined,
									uomValue: string
								): void => {
									onChangeUnitOfMeasure(
										UnitOfMeasureType.QUANTITY,
										uomValue as UOM,
										quantityValue
									);
								}}
								quantityValue={quantity}
								uomValue={qtyUom as UOMValue}
							/>
						</FlexItem>

						{/* Price UOM Field */}
						<FlexItem css={{ minWidth: "120px" }}>
							<Flex direction="column">
								<LabelContainer>
									<Typography color={label}>{content.priceUom}</Typography>
								</LabelContainer>
								<Flex className="puom-wrapper" css={{ borderRadius: 4 }}>
									<Dropdown
										id="puom"
										value={priceUom}
										options={priceUomDropdown}
										onFocus={() =>
											publishMessage(
												`to-debounce`,
												DebouncerPlusEvents.focus,
												"know-what-i-need"
											)
										}
										onChange={(value) => {
											publishMessage(
												`to-debounce`,
												DebouncerPlusEvents.change,
												"know-what-i-need"
											);
											onChangeUnitOfMeasure(
												UnitOfMeasureType.PRICE,
												value as UOM
											);
										}}
										size={controlSize}
										disabled={priceUomDropdown.length === 1}
										language={language}
										dataTestId="price-uom"
									/>
								</Flex>
							</Flex>
						</FlexItem>

						{/* Add to cart */}
						{hasPermissionRef.current && (
							<FlexItem
								css={{
									marginLeft: "10px",
									minWidth: "182px",
								}}
							>
								<ButtonContainer>
									<Button
										tabIndex={0}
										className={buttonClassName}
										label={content.addToCart}
										onClick={() => {
											if (!isDebouncing && Number(quantity) !== 0) {
												resetFocus.current = false;
												onClickAddToCart(() =>
													setTimeout(() => focusOnInput(), 1000)
												);
											}
										}}
										size="xl"
										disabled={isAddToCartDisabled}
										dataTestId="what-I-need-add-to-cart-button"
									/>
								</ButtonContainer>
							</FlexItem>
						)}
					</Flex>
					{showSelectedProduct && (
						<SelectedProduct
							quantity={quantity}
							qtyUom={qtyUom as UOM}
							priceUom={priceUom as UOM}
							content={content}
							selectedProduct={selectedProduct}
							setPrice={setPrice}
							getQueryString={getQueryString}
							leadTimeAndDeliveryDate={leadTimeAndDeliveryDate}
							setLeadTimeAndDeliveryDate={setLeadTimeAndDeliveryDate}
							canCallPricing={canCallPricing}
							isRetrieving={isRetrieving}
							setIsRetrieving={setIsRetrieving}
							retrievePricing={retrievePricing}
							setRetrievePricing={setRetrievePricing}
							setPricingResponseQuantity={setPricingResponseQuantity}
						/>
					)}
				</Flex>
			</div>
		</>
	);
};

export default KnowWhatINeedDesktop;
